<template>
  <div id="app" class="">
    
    <component :is="layout">
    </component>
  </div>
</template>

<script>
import LayoutDefault from "./views/layout/LayoutDefault.vue";
import LayoutFull from "./views/layout/LayoutFull.vue";

export default {
  components: {
    LayoutDefault,
    LayoutFull,
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "LayoutFull";
      return `LayoutDefault`;
    },
  },
};
</script>


import { defineComponent } from "vue";
//import { store as storeApi } from "@scrap-x/workspace/src/workspace";
import { store } from "@/store";

export default defineComponent({
  components: {},
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      name: "",
    };
  },
  computed: {
  },
  watch: {},
  methods: {
    submit: async function () {
      this.submitted = true;
      const res = {} as any;
      this.submitted = false;

      store.dispatch("workspace/add",  this.name);

      const el = this.$refs["closeBtn"] as any;
      this.$emit("reload");
      el.click();
      this.name = '';
    },
  },
});


import { Auth } from "aws-amplify";
import { store } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
  },
  data() {
    return {
      loaded: false
    };
  },
  computed: {
    isLoggedIn: function () {
      return store.getters.getUserSession;
    },
    fullName: function () {
      return store.getters["auth/getFullName"];
    },
    initials: function () {
      return store.getters["auth/getInitials"];
    },
    accounts: function () {
      return store.getters["account/getAccounts"];
    },
    activeAccount: function () {
      return store.getters["workspace/getActiveWorkspace"];
    },
    jwt: function () {
      return store.getters["getJWT"];
    },
    routeLoaded: function () {
      return this.$route.name
    }
  },
  watch: {
    jwt: {
      handler: function (val: any) {
        this.load();
      },
      immediate: true
    },
    routeLoaded: {
      handler: function (val: any) {
        this.load();
      },
      immediate: true
    }
  },
  methods: {
    editPermissions: function () {
      console.log("");
    },
    selectAccount: function (accountId: string) {
      store.dispatch("account/setActive", accountId);
    },
    logout: async function () {
      return store.dispatch("logout");
    },
    load: async function () {
      if (!this.jwt || !this.routeLoaded) {
        return;
      }
    },
  },
  mounted() {
    this.load();
  },
});

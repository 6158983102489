
import { defineComponent } from "vue";
import WebsiteDetail from "../../../show/components/WebsiteDetail.vue";
import WebsiteDetailImage from "../../../show/components/WebsiteDetailImage.vue";
import 'vue-diff/dist/index.css';

export default defineComponent({
  components: {
    WebsiteDetail,
    WebsiteDetailImage
  },
  props: {
    website: {
      type: Object,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
    };
  },
  watch: {
  },
  computed: {
  },
  methods: {
  },
});


import { defineComponent } from "vue";
import { upgrade as upgradeApi } from '@scrap-x/workspace/src/paddle';
import { store } from "@/store";
import { getProduct } from "./paddle";

export default defineComponent({
  components: {},
  emits: ["reload"],
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      submitted: false,
      name: "",
      error: "",
      done: false,
      fetchForUpdates: 0,
    };
  },
  computed: {
    selectedPlan: function () {
      return getProduct(this.productId);
    },
    getActivePriceId: function () {
      return store.getters['workspace/getActivePriceId'];
    },
  },
  watch: {
    productId: function () {
      this.name = '';
      this.done = false;
      this.submitted = false;
      this.error = '';
    },
  },
  methods: {
    checkForSubscriptionUpdate: async function () {
      this.fetchForUpdates++;
      store.dispatch('workspace/refreshActive')
      await new Promise(resolve => setTimeout(resolve, 1000));

      if (this.getActivePriceId === this.selectedPlan.priceId) {
        this.submitted = false;
        this.done = true;
        const el = this.$refs["closeBtn"] as any;
        el.click();
        this.$router.push({ name: 'index' });
      } else {
        if (this.fetchForUpdates > 6) {
          return alert("There was an error while upgrading your subscription. Please get in contact with us.");
        }
        setTimeout(() => {
          this.checkForSubscriptionUpdate();
        }, 2000);
      }
    },
    changeSubscription: async function () {
      this.submitted = true;
      this.error = '';

      await upgradeApi(
        store.getters['workspace/getActiveWorkspaceId'],
        this.selectedPlan.priceId,
        store.getters["getJWT"]
      ).catch((err) => {
        this.error = err.response.data; this.submitted = false;
      });

      this.checkForSubscriptionUpdate();

    },
  },
});

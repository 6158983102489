
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  emits: ["reload"],
  data() {
    return {
    };
  },
  watch: {},
});

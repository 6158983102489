import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-025b43db"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "search-and-user",
  class: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_feather_icon = _resolveComponent("feather-icon")!

  return (_ctx.isLoggedIn)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("a", {
          class: "logout",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
          href: "#"
        }, [
          _createVNode(_component_feather_icon, {
            type: "log-out",
            class: "icon",
            size: "18"
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "index-view" }
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mt-3" }
const _hoisted_3 = { class: "modal-content mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebsiteDetail = _resolveComponent("WebsiteDetail")!
  const _component_WebsiteDetailImage = _resolveComponent("WebsiteDetailImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.website.type !== 'IMAGE')
          ? (_openBlock(), _createBlock(_component_WebsiteDetail, {
              key: 0,
              website: _ctx.website
            }, null, 8, ["website"]))
          : _createCommentVNode("", true),
        (_ctx.website.type === 'IMAGE')
          ? (_openBlock(), _createBlock(_component_WebsiteDetailImage, {
              key: 1,
              website: _ctx.website
            }, null, 8, ["website"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}

import { defineComponent } from "vue";
//import { store as storeApi } from "@superdiv/account/src/account";
import { cancel as cancelApi } from '@scrap-x/workspace/src/paddle';
import { store } from "@/store";

export default defineComponent({
  components: {},
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      error: "",
      name: "",
    };
  },
  computed: {
  },
  watch: {},
  methods: {
    submit: async function () {
      this.submitted = true;
      this.error = "";

      const res = await cancelApi(
        store.getters['workspace/getActiveWorkspaceId'],
        store.getters["getJWT"]
      ).catch((err) => {
        this.error = err.response.data; this.submitted = false;
      });

      store.dispatch('workspace/refreshActive').then(() => {
        this.$router.push({ name: 'index' });
      });

      this.submitted = false;
      const el = this.$refs["closeBtn"] as any;
      this.$emit("reload");
      el.click();
      this.name = '';
    },
  },
});

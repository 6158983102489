
import { defineComponent } from "vue";
import { store } from "@/store";
import PaddleComponent from './Paddle/PaddleComponent.vue'
import CancelModal from './Paddle/CancelModal.vue'
import UpgradeModal from "./Paddle/UpgradeModal.vue";

export default defineComponent({
  components: {
    PaddleComponent,
    CancelModal,
    UpgradeModal
  },
  emits: ["setSelectedProductId"],
  data() {
    return {
      selectedProductId: ''
    };
  },
  computed: {
    getActiveWorkspace: function () {
      return store.getters['workspace/getActiveWorkspace'];
    },
  },
  watch: {},
  methods: {
    setSelectedProductId: function (productId: string) {
      this.selectedProductId = productId;
      this.$emit('setSelectedProductId', productId)
    }
  },
  mounted() {
    //
  },
});

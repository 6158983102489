
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      name: "",
    };
  },
  computed: {
    workspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
    workspace:function(){
      return store.getters["workspace/getActiveWorkspace"];
    }
  },
  mounted() {
    this.name = "";
  },
  methods: {
    submit: async function () {
      this.submitted = true;
      await store.dispatch("workspace/remove", this.workspace.workspaceId);
      
      this.submitted = false;
      this.name = "";
      this.$router.push({name:'index'});
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.$emit("reload");
    },
  },
});


import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
    components: {},
    emits: ["update:data"],
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            useList: false,
            mouseOver: false,
        };
    },
    watch: {
        data: {
            handler: function (v) {
                if (v.listSelector) {
                    this.useList = true;
                }
            },
            immediate: true
        },
    },
    computed: {
        computedData: {
            set(v: any) {
                this.$emit("update:data", v);
            },
            get() {
                return this.data;
            }
        },
        canUseBasicFeatures: function () {
            return store.getters["workspace/canUseBasicFeatures"];
        },
        canUseProFeatures: function () {
            return store.getters["workspace/canUseProFeatures"];
        },
    },

    methods: {
        goToPayment: function () {
            const el = this.$refs["closeBtn"] as any;
            el.click();
            this.$router.push({ name: "payment" });
        },
        addDataType() {
            this.computedData.dataType.push({ key: '', value: '' });
        },
        deleteDataType(index: number) {
            this.computedData.dataType.splice(index, 1);
        },
    },
});

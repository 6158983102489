const ENV = process.env.VUE_APP_SDK_ENVIRONMENT === 'production' ? 'production' : 'sandbox';

export const paddleConfig = {
    sandbox: {
        clientToken: 'test_e607fb128d541279c0cb9332682',
        env: 'sandbox',
        plans: [
            {
                name: 'Free', id: 'free', priceId: '', price: 0,
                active: true, websites: 1,
                features: ['Up to 1 Website', 'Textual Change Detection', 'Visual Change Detection', 'Data Extraction', 'E-Mail Notification', 'Webhook Triggers', 'Queryselectors', 'Send custom http headers', '<strike class="">Dedicated Support</strike>'],
                additional: ['No credit card required', 'Available for the first 7 days'],
            },
            {
                name: 'Basic', id: 'pro_01hp9dj48m35ttn99gvg67bvwy', priceId: 'pri_01hp9djz8atjsqgmrv48g6ra7x', price: 6,
                active: true, websites: 15,
                features: ['Up to 15 Websites', 'Textual Change Detection', 'Visual Change Detection', 'Data Extraction', 'E-Mail Notification', 'Webhook Triggers', 'Queryselectors', 'Send custom http headers', '<strike class="">Dedicated Support</strike>'],
            },
            {
                name: 'Pro', id: 'pro_01hp9dkhjh9ba7gqm5150cm5b8', priceId: 'pri_01hp9dmg48ew0z7abykvw6hgk1', price: 20,
                active: true, websites: 75,
                features: ['Up to 75 Websites', 'Textual Change Detection', 'Visual Change Detection', 'Data Extraction', 'E-Mail Notification', 'Webhook Triggers', 'Queryselectors', 'Send custom http headers', 'Dedicated Support'],
            },
            {
                name: 'Mega', id: 'pro_01j2vfn1xxcrmmzky4jebknw9n', priceId: 'pri_01j2vfp49mvx2wq30qsvr7p922', price: 50,
                active: true, websites: 225,
                features: ['Up to 225 Websites', 'Textual Change Detection', 'Visual Change Detection', 'Data Extraction', 'E-Mail Notification', 'Webhook Triggers', 'Queryselectors', 'Send custom http headers', 'Dedicated Support'],
            },
        ]
    },
    production: {
        clientToken: 'live_809171c22e7276c9cc1e87bf063',
        env: 'production',
        plans: [
            {
                name: 'Free', id: 'free', priceId: '', price: 0,
                active: true, websites: 1,
                features: ['Up to 1 Website', 'Textual Change Detection', 'Visual Change Detection', 'Data Extraction', 'E-Mail Notification', '>Webhook Triggers', 'Queryselectors', 'Send custom http headers', '<strike class="">Dedicated Support</strike>'],
                additional: ['No credit card required', 'Available for the first 7 days'],
            },
            {
                name: 'Basic', id: 'pro_01hp9nn270vxvr4gb1bvhwt9dg', priceId: 'pri_01hp9nnggbddaj1w9b6mp483a1', price: 6,
                active: true, websites: 15,
                features: ['Up to 15 Websites', 'Textual Change Detection', 'Visual Change Detection', 'Data Extraction', 'E-Mail Notification', 'Webhook Triggers', 'Queryselectors', 'Send custom http headers', '<strike class="">Dedicated Support</stirke>',],
            },
            {
                name: 'Pro', id: 'pro_01hp9nkr5n80swxdypdkq7a6ka', priceId: 'pri_01hp9nm513kme4ynd3wcevf4ek', price: 20,
                active: true, websites: 75,
                features: ['Up to 75 Websites', 'Textual Change Detection', 'Visual Change Detection', 'Data Extraction', 'E-Mail Notification', 'Webhook Triggers', 'Queryselectors', 'Send custom http headers', 'Dedicated Support']
            },
            {
                name: 'Mega', id: 'pro_01j2vfcywsq9d46gh9pvqz495w', priceId: 'pri_01j2vfev35sw5annpzw1w54ep9', price: 50,
                active: true, websites: 225,
                features: ['Up to 225 Websites', 'Textual Change Detection', 'Visual Change Detection', 'Data Extraction', 'E-Mail Notification', 'Webhook Triggers', 'Queryselectors', 'Send custom http headers', 'Dedicated Support']
            },
        ]
    }
}

export const getConfig = function () {
    const tmp = paddleConfig as any;
    return tmp[ENV];
};

export const getProduct = function (productId: string) {
    const tmp = paddleConfig as any;
    return tmp[ENV].plans.find((plan: any) => plan.id === productId);
};

//@ts-disable
import { defineComponent } from "vue";
import { store } from "@/store";
import dateFormat from "dateformat";
import AddWebsiteModal from "./modals/website/AddWebsiteModal.vue";
import DeleteWebsiteModal from "./modals/website/DeleteWebsiteModal.vue";
import UpdateWebsiteModal from "./modals/website/UpdateWebsiteModal.vue";
import ViewWebsiteModal from "./modals/website/ViewWebsiteModal.vue";
import LoadingSpinner from "@/views/components/LoadingSpinner.vue";
import WebsiteAddedModal from "./modals/website/WebsiteAddedModal.vue";

export default defineComponent({
    components: {
        AddWebsiteModal,
        DeleteWebsiteModal,
        UpdateWebsiteModal,
        ViewWebsiteModal,
        LoadingSpinner,
        WebsiteAddedModal,
    },
    data: () => {
        return {
            selectedWebsite: {},
            mouseOver: false,
        };
    },
    watch: {
    },
    computed: {
        getPossibleWebsites: function () {
            return store.getters["workspace/getPossibleWebsites"];
        },
        websites: function () {
            return store.getters["website/getWebsites"];
        },
        websitesLoaded: function () {
            return store.getters["website/websitesLoaded"];
        }
    },
    methods: {
        format: function (time: string) {
            return dateFormat(time, "dd.mm.yyyy HH:MM");
        },
        load: async function () {
            //
        },
    },
});

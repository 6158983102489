import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '../views/auth/LoginView.vue'
import { Auth, Hub } from "aws-amplify";
import { store } from '@/store'
import pages from './pages'

let timeout: ReturnType<typeof setTimeout>
function getUserSesion() {
  return Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('setUserSession', data);
      return data;
    }
  }).catch(() => {
    store.commit('setUserSession', null);
    return null;
  });
}

function refresToken() {
  timeout = setTimeout(() => {
    Auth.currentSession()
      .then((session) => {
        store.commit('setUserSignInToken', session);
      })
      .catch(() => {
        store.commit('setUserSession', null);
        clearTimeout(timeout);
      })
  }, 15 * 60 * 1000)
}

Hub.listen("auth", async (data) => {
  console.log('data.payload.event', data.payload.event, data.payload.data);
  switch (data.payload.event) {
    case "signIn":
      //store.dispatch("getUserDate");
      store.commit('setUserSession', data.payload.data);
      refresToken();
      router.push({ name: 'index' });
      break;
    case "signOut":
      store.commit('setUserSession', null);
      clearTimeout(timeout);
      router.push({ name: 'login' });
      break;
  }
});

const routes: Array<RouteRecordRaw> = [
  ...pages,
  ////////////
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      layout: 'full'
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeResolve(async (to, from, next) => {
  if(!timeout){
    refresToken();
  }
  
  const user = await getUserSesion();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //if (!user || !store.state.user) {
    if (!store.getters.getJWT) {
      return next({
        path: '/login'
      });
    }
    return next()
  }
  if (to.name === 'login' && store.getters.getJWT) {
    return next({
      path: '/'
    });
  }
  return next()
});

export default router

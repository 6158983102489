import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0759f316"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "",
  class: "container mt-4"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-2" }
const _hoisted_4 = { class: "navbar-nav" }
const _hoisted_5 = { class: "nav-item dropdown" }
const _hoisted_6 = {
  class: "dropdown-toggle h4",
  href: "#",
  id: "navbarDarkDropdownMenuLink",
  role: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_7 = {
  class: "dropdown-menu",
  "aria-labelledby": "navbarDarkDropdownMenuLink"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  class: "dropdown-item",
  ref: "addWorkspaceModal",
  "data-bs-toggle": "modal",
  "data-bs-target": "#addWorkspaceModal",
  href: "#"
}
const _hoisted_10 = { class: "col-md-8 text-center" }
const _hoisted_11 = { class: "col-md-2 text-end" }
const _hoisted_12 = {
  ref: "addFirstWorkspaceModal",
  "data-bs-toggle": "modal",
  "data-bs-target": "#addFirstWorkspaceModal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_search_and_user_info = _resolveComponent("search-and-user-info")!
  const _component_add_workspace_modal = _resolveComponent("add-workspace-modal")!
  const _component_add_first_workspace_modal = _resolveComponent("add-first-workspace-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.getActiveWorkspace.name), 1),
            _createElementVNode("ul", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workspaces, (w) => {
                return (_openBlock(), _createElementBlock("a", {
                  class: _normalizeClass(`dropdown-item ${w.workspaceId === _ctx.getActiveWorkspace.workspaceId ? 'activeWebsite' : ''}`),
                  href: "#",
                  key: w.workspaceId,
                  onClick: ($event: any) => (_ctx.selectWorkspace(w.workspaceId))
                }, _toDisplayString(w.name), 11, _hoisted_8))
              }), 128)),
              _createElementVNode("a", _hoisted_9, "[+] Add Workspace", 512)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_router_link, {
          to: { name: 'index' },
          class: "router-link me-4"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Targets")
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: { name: 'notifications' },
          class: "router-link me-4"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Settings")
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: { name: 'payment' },
          class: "router-link me-4"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Your Plan")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_search_and_user_info)
      ])
    ]),
    _withDirectives(_createElementVNode("a", _hoisted_12, null, 512), [
      [_vShow, false]
    ]),
    _createVNode(_component_add_workspace_modal),
    _createVNode(_component_add_first_workspace_modal)
  ]))
}
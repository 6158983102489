import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { TState } from '@/types/TState';
import { Auth } from "aws-amplify";
import workspace from './workspace';
import website from './website';

const API_URL = '/api'

// define injection key
export const key: InjectionKey<Store<TState>> = Symbol()

export const store = createStore<TState>({
  plugins: [],
  state: {
    userSession: null,
  },
  getters: {
    getUserSession(state) {
      return state.userSession
    },
    getJWT(state) {
      return state.userSession ? state.userSession.signInUserSession?.idToken.jwtToken : '';
    },
    getUserEmail(state) {
      return state.userSession ? state.userSession.signInUserSession?.idToken.payload.email : '';
    },
  },
  mutations: {
    setUserSession(state, session) {
      state.userSession = session;
    },
    setUserSignInToken(state, session) {
      state.userSession = { ...state.userSession, signInUserSession: session }
    },
    logout(state) {
      state.userSession = null;

      clearAllCookie();
    }
  },
  actions: {
    async logout({ commit }) {
      Auth.signOut();
      commit('logout');
    },
  },
  modules: {
    workspace,
    website
  }
})

export function useStore() {
  return baseUseStore(key)
}

function clearAllCookie() {
  const cookies = document.cookie.split("; ");
  for (let c = 0; c < cookies.length; c++) {
    const d = window.location.hostname.split(".");
    while (d.length > 0) {
      const cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
      const p = location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
}

import { defineComponent } from "vue";
//import { store as storeApi } from "@scrap-x/workspace/src/workspace";
import { store } from "@/store";
import WebsiteConfig from "./components/WebsiteConfig.vue";

export default defineComponent({
  components: {
    WebsiteConfig
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      error: '',
      headers: [] as any[],
      active: true,
      url: "",
      data: {
        type: "TEXT",
        dataType: [] as any[],
        transformer: "",
        listSelector: "",
        screenshotClickSelector: ""
      } as any,
    };
  },
  computed: {
    canUseBasicFeatures: function () {
      return store.getters["workspace/canUseBasicFeatures"];
    },
    canUseProFeatures: function () {
      return store.getters["workspace/canUseProFeatures"];
    },
    correctWebsiteUrl: function () {
      return this.data.url && (this.data.url.startsWith("http://") || this.data.url.startsWith("https://"));
    },
  },
  watch: {},
  methods: {
    goToPayment: function () {
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.$router.push({ name: "payment" });
    },
    addHeader() {
      this.headers.push({ key: '', value: '' });
    },
    deleteHeader(index: number) {
      this.headers.splice(index, 1);
    },
    reset: function () {
      this.data = {
        type: "TEXT",
        dataType: [] as any[],
        transformer: "",
        listSelector: "",
        screenshotClickSelector: "",
      };

      this.headers = [] as any[];
      this.active = true;
      this.url = "";

      this.submitted = false;
      this.error = "";
    },
    submit: async function () {
      this.submitted = true;
      this.error = "";

      await store.dispatch("website/add", { ...this.data, url: this.data.url, headers: this.headers, active: this.active })
        .then(() => {
          const el = this.$refs["showAddedModal"] as any;
          this.submitted = false;
          this.$emit("reload");
          el.click();
          this.reset();
        })
        .catch((err: any) => {
          store.dispatch("website/loadAll");
          if (err.response && err.response.data && err.response.data.e && err.response.data.e.message) {
            this.error = err.response.data.e.message;
          } else {
            this.error = "An error has occurred and we will NOT automatically scrape your website. Please update your website: " + JSON.stringify(err.response);
          }
        });
    },
  },
});

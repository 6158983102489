import { RouteRecordRaw } from "vue-router";
import IndexView from '../views/pages/index/IndexView.vue'
import WebsiteView from '../views/pages/show/WebsiteView.vue'
import WebsiteOptOutView from '../views/pages/optOut/WebsiteOptOutView.vue'
import NotificationsView from '../views/pages/settings/NotificationsView.vue'
import PaymentView from '../views/pages/settings/Payment/PaymentView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'index',
        component: IndexView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/:workspaceId/:websiteId',
        name: 'website',
        component: WebsiteView,
        meta: {
        }
    },
    {
        path: '/opt-out/:workspaceId/:websiteId/:title',
        name: 'website-opt-out',
        component: WebsiteOptOutView,
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: NotificationsView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/payment',
        name: 'payment',
        component: PaymentView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/invitiations',
        name: 'invitations',
        component: IndexView,
        meta: {
            requiresAuth: true,
        }
    }
];

export default routes;

//@ts-disable
import { defineComponent } from "vue";
import { optOut as optOutApi } from "@scrap-x/website/src/website";

export default defineComponent({
  data() {
    return {
      isSubmitting: false,
      isSubmitted: false,
    };
  },
  methods: {
    async submit() {
      this.isSubmitting = true;

      await optOutApi(
        String(this.workspaceId),
        String(this.websiteId),
      );

      this.isSubmitted = true;
    },
  },
  computed: {
    workspaceId: function () {
      return this.$route.params.workspaceId;
    },
    websiteId: function () {
      return this.$route.params.websiteId;
    },
    title: function () {
      return this.$route.params.title;
    },
  }
});


import router from "@/router";
import { store } from "@/store";
import SearchAndUserInfo from "./SearchAndUserInfo.vue";
import { defineComponent } from "vue";
import AddWorkspaceModal from "../modals/workspace/AddWorkspaceModal.vue";
import AddFirstWorkspaceModal from "../modals/workspace/AddFirstWorkspaceModal.vue";


export default defineComponent({
  components: {
    SearchAndUserInfo,
    AddWorkspaceModal,
    AddFirstWorkspaceModal
  },
  data() {
    return {
    };
  },
  watch: {
    getUserEmail: function (v: any) {
      if (v) {
        this.load();
      }
    },
    getJWT: function (v: any) {
      if (v) {
        this.load();
      }
    },
    hasNoWorkspaces: function (v) {
      if (v) {
        const el = this.$refs["addFirstWorkspaceModal"] as any;
        el.click();
      }
    }
  },
  computed: {
    getActiveWorkspace: function () {
      return store.getters["workspace/getActiveWorkspace"];
    },
    workspaces: function () {
      return store.getters["workspace/getWorkspaces"];
    },
    getUserEmail: function () {
      return store.getters["getUserEmail"];
    },
    getJWT: function () {
      return store.getters["getJWT"];
    },
    hasNoWorkspaces: function () {
      return store.getters["workspace/hasNoWorkspaces"];
    },
  },
  methods: {
    selectWorkspace: function (workspaceId: string) {
      store.dispatch("workspace/setActive", workspaceId);
    },
    goTo: function (params: any) {
      router.push(params).catch();
    },
    load: function () {
      if (this.getJWT && this.getUserEmail && this.getUserEmail) {
        store.dispatch("workspace/loadAll");

      }
    }
  },
  mounted() {
    this.load();
  }
});

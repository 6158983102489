
import { defineComponent } from "vue";
import { store } from "@/store";
import { transactions as transactionsApi } from '@scrap-x/workspace/src/paddle';
import { invoice as invoiceApi } from '@scrap-x/workspace/src/paddle';

export default defineComponent({
    components: {},
    data() {
        return {
            transactions: [],
            loading: false,
        };
    },
    account: function () {
        return store.getters['workspace/getActiveWorkspace'];
    },
    watch: {
        account: function () {
            this.transactions = [];
            this.loading = false;
        }
    },
    methods: {
        loadTransactions: async function () {
            this.transactions = [];
            this.loading = true;
            const res = await transactionsApi(
                store.getters['workspace/getActiveWorkspaceId'],
                store.getters["getJWT"]
            ) as any;
            this.transactions = res.data;
            this.loading = false;
        },
        openPdf: async function (transactionId: any) {
            const link = await invoiceApi(
                store.getters['workspace/getActiveWorkspaceId'],
                transactionId,
                store.getters["getJWT"]
            ) as any;
            window.open(link.data.url, '_blank');
        }
    },
    mounted() {
        this.transactions = [];
        this.loading = false;
    },
});

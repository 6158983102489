
import { defineComponent } from "vue";
import { store } from "@/store";
import { updateAddress as updateAddressApi } from '@scrap-x/workspace/src/paddle';

export default defineComponent({
  components: {},
  emits: ["next"],
  data() {
    return {
      storing: false,
      error: false,
      businessInformation: {
        address: {
          countryCode: "",
          postalCode: "",
          region: "",
          city: "",
          firstLine: "",
        },
        business: {
          name: "",
          taxIdentifier: "",
        },
      },
      initialBusinessInformation: {
        address: {
          countryCode: "",
          postalCode: "",
          region: "",
          city: "",
          firstLine: "",
        },
        business: {
          name: "",
          taxIdentifier: "",
        },
      },
    };
  },
  computed: {
    account: function () {
      return store.getters['workspace/getActiveWorkspace'];
    },
    noAddress: function (): boolean {
      if (this.businessInformation && this.businessInformation.address) {
        return !this.businessInformation.address.firstLine
          || !this.businessInformation.address.postalCode
          || !this.businessInformation.address.countryCode;
      }
      return true;
    },
  },
  watch: {
    account: function () {
      this.reset();
    }
  },
  methods: {
    reset: function () {
      if (this.account &&
        this.account.businessInformation &&
        this.account.businessInformation.address &&
        this.account.businessInformation.address.firstLine) {
        this.businessInformation = JSON.parse(JSON.stringify(this.account.businessInformation));
      } else {
        this.businessInformation = JSON.parse(JSON.stringify(this.initialBusinessInformation));
      }
    },
    submit: async function () {
      this.storing = true;
      this.error = false;

      await updateAddressApi(
        store.getters['workspace/getActiveWorkspaceId'],
        this.businessInformation,
        store.getters["getJWT"]
      ).catch((err) => {
        this.error = true;
      });

      await store.commit('workspace/UPDATE_WORKSPACE', {
        ...this.account,
        businessInformation: JSON.parse(JSON.stringify(this.businessInformation))
      });
      this.storing = false;

      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.$emit("next");
    }
  },
});
